import './MainStyles.less';
import BusinessCard from './BusinessCard/BusinessCard';
import Timer from './Timer/Timer';
import React, { useLayoutEffect, useState, useEffect } from 'react';
import os_obd2_img_0 from "../assets/images/android/frontpage.png"
import os_loan_calculator_img_0 from "../assets/images/android/loan_calculator.png"
import os_northfinder_img_0 from "../assets/images/android/northfinder.png"
import os_moon_defender_img_0 from "../assets/images/android/moon_defender.png"
import os_bucket_ball_img_0 from "../assets/images/android/bucket_ball.png"
import { Grid } from 'lucide-react';

function Home() {

    return (
      <div className="Home" >
       <div className="top1" >
        <div className=' AltSecB'>
        <h4 style={{margin:'.5em 2em 3em 2em'}}>Expert Mobile, Desktop and Web Designs by Mike&nbsp;Otterbine</h4>

        {/* <div style={{display:'grid',placeItems:'center', margin:'4em 0 4em 0'}}>
          <hr id="obdii-windows" style={{width:'50%',textAlign:'center', border:'1px solid #FFF', height:'1px',  opacity:.2}}/>
        </div>       */}


        {/* OS OBDII */}
        <div className='container-fluid'>

          <div style={{display:'grid',placeItems:'center', margin:'4em 0 1em 0'}}>
            <hr id="obdii-windows" style={{width:'80%',textAlign:'center', border:'1px solid #FFF', height:'1px',  opacity:.2}}/>
          </div>   

          <h4 className='d-block d-md-none' style={{margin:'.5em 0 2em 0'}}><b>OS OBDII Interface</b></h4>

          <div className="row justify-content-center" style={{}}>
            <div className="col-md-4 " style={{display:'grid', placeItems:'center'}}>
              <div>
                <img className='screenshot_profile d-none d-md-block' src={os_obd2_img_0} style={{width:'100%',  display:'inline-block'}}/>
              </div>
            </div> 
            <div className="col-md-6 " style={{display:'grid', placeItems:'center', padding:'0em 1em 0 .5em', margin:0}}>
              <div>
                <h4 className='d-none d-md-block' style={{margin:'0em 0 1.5em 0'}}>OS OBDII Interface</h4>
                <img className='screenshot_profile d-block d-md-none' src={os_obd2_img_0} style={{margin:'0 1.0em 0 1em', height:'100%', display:'inline-block',float:'left'}}/>
                <div>
                <p>OS OBDII Interface is a basic automotive scan tool application that supports a variety of Bluetooth, WiFi and USB hardware devices.</p>
                <p>Available in both Android and Windows platforms, OS OBDII Interface is an interactive troubleshooting tool. A vehicle's trouble codes, emission monitors as well as live data can be accessed with the app.</p>
                </div>
                </div>
              <div style={{marginTop:'1.5em',padding:0, textAlign:'center'}}>
                  <a target="_blank" href='https://play.google.com/store/apps/details?id=com.OtterbineSolutions.os_obd2'>
                    <div className='gPlayLink' style={{margin:'1em 0', display:'inline-block', textAlign:'center', alignItems:'center'}}>
                      <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                    </div>
                  </a>
                  <br/>
                <a href='/downloads/OS_OBDII.zip'>
                  <div style={{display:'inline-block'}}>
                    <h5>Download Windows App</h5>
                    <small>(Unpackaged Release zip file)</small>
                  </div>
                </a>
              </div>
            </div> 
          </div> 
        </div>


     
       
        {/* OS LOAN CALCULATOR */}

        <div style={{display:'grid',placeItems:'center', margin:'4em 0 .5em 0'}}>
          <hr id="loancalculator-windows" style={{width:'80%',textAlign:'center', border:'1px solid #FFF', height:'1px',  opacity:.2}}/>
        </div> 

        <h4 className='d-block d-md-none' style={{margin:'0em 0 2em 0'}}><b>OS Loan Calculator</b></h4>

        <div className='container-fluid' style={{marginBottom:'2em'}}>
          <div className="row justify-content-center" style={{}}>
            <div className="col-md-4 " style={{display:'grid', placeItems:'center'}}>
              <div>
                <img className='screenshot_profile d-none d-md-block' src={os_loan_calculator_img_0} style={{ width:'100%'}}/>
              </div>
            </div> 
            <div className="col-md-6" style={{display:'grid', placeItems:'center', padding:'1em 0 0 0', margin:0}}>
              <div style={{position:'relative'}}>
                <h4 className='d-none d-md-block' style={{margin:'0em 0 1.5em 0'}}>OS Loan Calculator</h4>
                <img className='screenshot_profile d-block d-md-none' src={os_loan_calculator_img_0} style={{position:'static', margin:'0em 2em 0em .5em', height:'100%', display:'inline-block',float:'inline-end', top:'35px'}}/>
                  <p>OS Loan Calculator is an amortization calculator that creates a schedule of payments based on loan amount, annual percentage rate and compounding period.</p>
                  <p>In addition to comparing the impact of varying interest rates, the user can calculate and see the impact extra payments make on the balance and duration of a loan.</p>
                  <p>Available in both Android and Windows platforms, OS Loan Calculator is a simple loan calculation tool, especially handy for measuring and comparing various loan scenarios.</p>
              </div>
              <div style={{marginTop:'1.5em',padding:0, textAlign:'center'}}>
                <a target="_blank" style={{padding:0}} href='https://play.google.com/store/apps/details?id=com.OtterbineSolutions.moneytimes'>
                  <div style={{margin:'1em 0', height:'80px', display:'inline-block'}}>
                    <img height="1em" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                  </div>
                </a>
                <br/>
                <a href='/downloads/LoanCalculator.zip' >
                  <div style={{display:'inline-block', }}>
                    <h5>Download Windows App</h5>
                    <small>(Unpackaged Release zip file)</small>
                  </div>
                </a>
              </div>
            </div> 
          </div> 
        </div>


      </div>


      {/* OS North Finder */}

      <div className='AltSecW'>
        <h4 className='d-block d-md-none' style={{margin:'0em 1.5em 1.5em 1.5em'}}><b>North Finder - 3D Compass </b><small>(Android)</small></h4>
        <div className='container-fluid'>
          <div className="row justify-content-center" style={{}}>
            <div className="col-md-4 col-lg-4 d-none d-md-block" style={{display:'grid', placeItems:'center'}}>
              <div>
                <img className='screenshot_profile d-none d-sm-block' src={os_northfinder_img_0} style={{margin:'0', width:'100%', display:'inline-block'}}/>
              </div>
            </div> 
            <div className="col-md-6 col-lg-6" style={{display:'grid', placeItems:'center', alignContent:'center', padding:'1em 0 0 0', margin:0}}>
              <div style={{margin:0,padding:0,  gridRow:'0'}}>
                <h4 className='d-none d-md-block' style={{margin:'0em 1.5em 1em 1.5em'}}>North Finder - 3D Compass <small>(Android)</small></h4>
                <img className='screenshot_profile d-block d-md-none' src={os_northfinder_img_0} style={{margin:'0 1em 0 1em ', height:'100%', display:'inline-block',float:'left'}}/>
                <p>OS North Finder is a simple, 3D compass. The display shows a floating compass that is fully functional and intuitive regardless of device orientation.</p>
                <p>Operation is a matter of setting a desired degree offset and observing.</p>
                <p className='small'>(full functionality requires the host device contains a magnetometer and a  3-dimensional accelerometer)</p>
              </div>
              <div style={{marginTop:'1.5em',padding:0, textAlign:'center', gridRow:'0'}}>
                <a target="_blank" href='https://play.google.com/store/apps/details?id=com.otterbinesolutions.osnorthfinder'>
                  <div style={{ height:'80px', display:'inline-block'}}>
                    <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                  </div>
                </a>
              </div>
            </div> 
          </div> 
          </div>
          </div>

        </div>

        <div className='top1'>

          {/* Moon Defender */}
          <div className='AltSecB'>
            <h4 style={{margin:'1em 0 2em 0'}}>Moon Defender <small>(Android)</small></h4>
            <div className='cardContainer'>
              <div style={{display:'grid', placeItems:'center'}}>
                <img src={os_moon_defender_img_0} style={{border:'1px solid #555', borderRadius:'10px', width:'90%'}} />
              </div>
              <div style={{margin:'2em 0 0 0', padding:0,display:'grid', placeItems:'center'   ,maxWidth:'68%'}}>
                <div >
                <p>Moon Defender is a 3D 'shoot 'em up' game of attrition. You must keep on top of the incoming enemy before they take you out. The longer you play the harder it gets.</p>
                  <div style={{height:'80px',padding:0, marginBottom:'2em', textAlign:'center'}}>
                    <a target="_blank" style={{padding:0}} href='https://play.google.com/store/apps/details?id=com.otterbinesolutions.osmoondefender'>
                      <div style={{margin:'1em 0', height:'80px', display:'inline-block'}}>
                        <img height="1em" style={{margin:'1em 0'}} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Bucket Ball */}
          <div className='AltSecW' style={{marginBottom:'5em'}}>
            <h4 className='d-block d-md-none' style={{margin:'0em 0 2em 0'}}><b>Bucket Ball </b><small>(Android)</small></h4>
            <div className='container-fluid'>
              <div className="row justify-content-center" >
                <div className="col-sm-4" style={{display:'grid', placeItems:'center'}}>
              {/* <label className='small'>for</label> */}
                {/* <label style={{padding:'0 0 0.5em 0'}}>Android and Windows</label> */}
                  <img className='screenshot_profile d-none d-sm-block' src={os_bucket_ball_img_0} style={{border:'1px solid #555', borderRadius:'10px'}} />
                </div>

                <div className="col-sm-6" style={{display:'grid', placeItems:'center', alignContent:'center', padding:'1em 0 0 0', margin:0}}>
                {/* <div style={{margin:'1em 0 0 0', padding:0,display:'grid', placeItems:'center'   ,maxWidth:'68%'}}> */}
                <div >
                <h4 className='d-none d-md-block' style={{margin:'0em 0 1.5em 0'}}>Bucket Ball <small>(Android)</small></h4>
                <img className='screenshot_profile d-block d-sm-none' src={os_bucket_ball_img_0} style={{margin:'0 1em ', height:'100%', display:'inline-block',float:'left'}} />
                <p>Bucket Ball is a carnival-like 3D game with various difficulty levels and scoring. Get the ball in the ring which can vary in size.</p>
                  <div style={{height:'80px',padding:0, textAlign:'center'}}>
                    <a target="_blank" style={{padding:0}} href='https://play.google.com/store/apps/details?id=com.otterbinesolutions.bucketball'>
                      <div style={{margin:'1em 0', height:'80px', display:'inline-block'}}>
                        <img height="1em" style={{margin:'1em 0'}} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                      </div>
                    </a>
                  </div>
                </div>


                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

    );

  }


  export default Home