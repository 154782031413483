import React from 'react';
import './Footer.less';

var copyRightText = "Otterbine Solutions " + new Date().getFullYear();

function Footer(){
  //  return <div className="footer-class"><h1>Whammy Jammeny</h1></div>


    return <footer className="footerbackground noprint">
    <div id="footerarea" className="footertext" >
      <p>{copyRightText}</p>
    </div>
  </footer >
  
}

export default Footer


