import { useState} from 'react';
import React from 'react';
import './ImageCarousel.less';


import { LucideSquareArrowLeft,LucideSquareArrowRight, ArrowBigRight, ArrowBigLeft } from 'lucide-react';

type ImageSlide = {
  url:string,
  title:string
}
type ImageSliderProps = {
  // imageUrls:string[]
  imageUrls:ImageSlide[];
}



export default function ImageCarousel({imageUrls}:ImageSliderProps){

    const [imageIndex, setimageIndex] = useState(0)

    function showNextImage() {
      setimageIndex(index => {
        if(index >= imageUrls.length - 1) return 0;
        return index+1;
      })
    }
    
    function showPrevImage() {
      setimageIndex(index => {
        if(index <= 0) return  imageUrls.length-1;
        return index-1;
      })
    }


     return <div className="ImageCarousel noprint" style={{width:'100%', height:'100%',maxWidth:'400px', margin:'0 auto 0 50%', transform:'translate(-50%)'}}>
   

        {/*  The slideshow  */}
        <div className="" style={{width:'100%',height:'100%',overflow:'hidden', position:'relative',margin:0, padding:0}}>
            <div style={{overflow:'hidden', margin:0, padding:0, display:'flex'}}>
            {imageUrls.map(rec=>(
              <img key={rec.url} src={rec.url} className='img-slider-img' style={{translate:`${(-100*imageIndex)}%` }}/>
            ))}

            {/* <img src={imageUrls[imageIndex].url} style={{ marginLeft:'50%' ,transform:'TranslateX(-50%)'}} className='' alt=""/>  */}

            <button onClick={showPrevImage} className='img-slider-btnl' ><ArrowBigLeft/></button>
            <button onClick={showNextImage} className='img-slider-btnr' ><ArrowBigRight/></button>    
          </div>
        </div>
        {/* <div style={{textAlign:'center'}}>
          <div className="carousel-caption" style={{zIndex:99}} >
            <p>" + element.title wit some mamma jaammama wut aym sayin + "</p>
          </div>
        </div> */}

        {/* Indicators  */}
        <ul id="carousel-indicators" className="carousel-indicators" >
          {/* <li data-target="#carousel" data-slideto={imageIndex} className=""></li> */}
          {/* <li><div style={{textAlign:'center'}}>
              <div className="carousel-caption" style={{zIndex:99}} >
              <p>" + element.title wit some mamma jaammama wut aym sayin + "</p>
              
              </div>
            </div>
            </li> */}
        </ul>


     
      <div style={{textAlign:'center', width:'100%'}}>
        <div style={{fontWeight:700, fontSize:'1.0em', margin:'1em 0 0 0', padding:0 }} >
          
          <h5 style={{textAlign:'center'}}><strong>{imageUrls[imageIndex].title}</strong></h5>
        </div>
      </div>
      
  </div >
  
}

//export default ImageCarousel


