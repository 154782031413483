import React, {useState, useEffect, Component} from "react";
import "./Timer.less"



//  function doTimerThing() {
//     const [temp, setTemp] = useState(0);
//  }

function Timer(){

    const state = "NJ";
    const city = "Keyport";

    const url = "https://us-weather-by-city.p.rapidapi.com/getweather?city=" + city + "&state=" + state;
    // const jack = "${url}?city=${city}&state=${state}";
    //console.log(url);
    console.log("**** Timer function called...");
    const [temp, setTemp] = useState(0);

    useEffect(() => {

        const fetchData = async () => {

            console.log("**** fetchData called...");
            const result = await fetch(url,{
                method: 'GET',
                headers: {"X-RapidAPI-Key":"e454b77ecamsh88d4de534e40b2fp130c0ajsnb2aeed24aa32",
                          "X-RapidAPI-Host":"us-weather-by-city.p.rapidapi.com"}
            });

            console.log("Contents: " + JSON.stringify(result));

        }
        fetchData();

        // const fetchData = async () => {
        //     const result = await fetch(url,{
        //         method: 'POST',
        //         headers: {"X-RapidAPI-Key":"e454b77ecamsh88d4de534e40b2fp130c0ajsnb2aeed24aa32",
        //                   "X-RapidAPI-Host":"us-weather-by-city.p.rapidapi.com"},
        //         body: JSON.stringify(targetObject)
        //     }).then(()=>{
        //
        // 
        //     });
        // }

    });

    
    return(
        <div>
            <label style={{margin:"30px,auto"}}>Temp: {temp}F</label>
        </div>
    )
}

// class Timer extends Component {

 
//     render(){
//         return(
//             <div>
//                 <label style={{margin:"30px,auto"}}>Temp: {temp}F</label>
//             </div>
//         )
//     }
// }

export default Timer