import './PrivacyPolicy.less';

var adminEmail = "admin@otterbinesolutions.com";
var mobileAppName = "OS Interest Calculator";
var author = "Michael Otterbine";

function MoneyTimesAppPrivacyPolicy() {

   // Turn off the header
   window.bShowHeader = "none";

    return (

<div id="MoneyTimesAppPrivacyPolicy" className="container privacy-policy" style={{height:'auto',minHeight: '400px',paddingBottom: '65px',paddingTop:'45px'}}>
  <h2 >PRIVACY POLICY FOR MOBILE APPLICATION "{mobileAppName}"</h2>  

  <p>Last updated: September 1, 2023</p>
  <p>
      This privacy policy governs your use of the software application “{mobileAppName}” (later referred as the/this “Application”) for Android™ mobile devices that was created by {author}.
  </p>
  <p>
      The Application is a mobile Android application that performs standard OBD2 vehicle functions in conjunction with 3rd-party Wifi and Bluetooth modules.
  </p>

  <h3 style={{marginTop: '50px'}}>What information does the Application obtain and how is it used?</h3>

  <h3>User Provided Information</h3>
  <p>
      The Application does not require any user registration. The Application does not require the user to provide any personal information.
  </p>
  <p>
    There is no User Provided data for this Application.
 </p>


  <h3>Automatically Collected Information</h3>
  <p>
      The Application does not collect any information automatically.
  </p>
  <p>
      The Application does not share any information automatically with third parties.
  </p>

  <ul>
      <li>Does the Application collect precise real time location information of the device?</li>
  </ul>
  <p>The Application does not collect precise information about the location of your mobile device.</p>

  <ul>
      <li>Do third parties see and/or have access to information obtained by the Application?</li>
  </ul>
  <p>Not applicable since the Application does not collect any information automatically.</p>
  
  <ul>
      <li>What are my opt-out rights?</li>
  </ul>
  <p>You can easily delete all User Provided Data by uninstalling the Application from your mobile device. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network.</p>



  <h2 style={{marginTop: '50px'}}>Data Retention Policy, Managing Your Information</h2>
  <p>
      This Application does not automatically collect, share nor retain any information. All vehicle details remain local to your mobile device, within the Application private folder, following Android’s security best practices.
  </p>



  <h2 style={{marginTop: '50px'}}>Children</h2>
  <p>
      Our Application does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. We do not use the Application to knowingly solicit data from or market to children under the age of 13. 
  </p>



  <h2 style={{marginTop: '50px'}}>Security</h2>
  <p>
      We are concerned about safeguarding the confidentiality of your information stored locally on your mobile device. 
  </p>
  <p>
      Please be aware that, although we endeavor provide reasonable security for information we process and maintain, no security system can prevent all potential security breaches (e.g., a zero-day attack targeting mobile Operating System).
  </p>
  <p>
      In order to ensure an optimal security, you shall regularly install any Application’s update available from the mobile application marketplace. 
  </p>
  <p>
      We also advise you to regularly check for any Android OS update(s) that could be available and to enable automatic updates.
  </p>



  <h2 style={{marginTop: '50px'}}>Changes</h2>

  <p>
      You are advised to review this Privacy Policy periodically for any changes, as continued use is deemed approval of all changes. Changes to this Privacy Policy are effective when they are posted on this page. 
  </p>
  <p>
      Please be aware that, although we endeavor provide reasonable security for information we process and maintain, no security system can prevent all potential security breaches (e.g., a zero-day attack targeting mobile Operating System).
  </p>



  <h2 style={{marginTop: '50px'}}>Your Consent</h2>
  <p>
      By using the Application, you only consent to the local processing of your information.
  </p>
  <p>
      At any time, no User Data (such as VIN and PID Codes) will be shared, processed and/or stored by third parties. User Provided Data cannot and will never be sold to any other third parties.
  </p>



  <h2 style={{marginTop: '50px'}}>Contact Us</h2>
  <p>
      If you have any questions regarding privacy while using the Application, or have questions about our practices, please contact us via email at: <br/>{adminEmail}
  </p>



  <p style={{marginTop: '50px'}}><i>
      The "Android" name, the Android logo, the "Google Play" brand, and other Google trademarks, are property of Google LLC and not part of the assets available through the Android Open Source Project.
  </i>
</p>
</div>


    );




  }


  export default MoneyTimesAppPrivacyPolicy